import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

const enterAnimation: string = '300ms ease-in-out';
const leaveAnimation: string = '300ms ease-in-out';

export const slideToggleParent: AnimationTriggerMetadata = trigger('slideToggleParent', [transition(':enter', [])]);

export const slideToggle: AnimationTriggerMetadata = trigger('slideToggle', [
  transition(':enter', [style({ height: 0 }), animate(enterAnimation)]),
  transition(':leave', [animate(leaveAnimation, style({ height: 0 }))]),
]);
